import companyName from '../store/mobxStore/companyName';
const COMPANY_NAME =companyName.companyName??'Company Name';
const BASE_URL = "https://backoffice.a4jfashion.com/backoffice/api/";
const IMG_URL = "https://backoffice.a4jfashion.com/uploads/images/";
const BASE = "https://backoffice.a4jfashion.com/";
const API_KEY =localStorage.getItem('apiKey'); //binaryaddon;
const DEFAULT_KEY = '6777e535-0c38-44e5-858f-f9f9823e518c';

export {
    COMPANY_NAME,
    BASE_URL,
    IMG_URL,
    API_KEY,
    BASE,
    DEFAULT_KEY
}


// import companyName from '../store/mobxStore/companyName';
// const COMPANY_NAME =companyName.companyName??'Company Name';
// const BASE_URL = "http://demo8.infinitemlmdemo.com/julisa/backoffice/api/";
// const IMG_URL = "http://demo8.infinitemlmdemo.com/julisa/uploads/images/";
// const BASE = "http://demo8.infinitemlmdemo.com/julisa/";
// const API_KEY =localStorage.getItem('apiKey'); //binaryaddon;
// // const DEFAULT_KEY = 'ecb58d1f-e6f0-49ad-b63e-0d77a54aa31d';
// const DEFAULT_KEY = '6777e535-0c38-44e5-858f-f9f9823e518c'; //Demo 8 api key

// export {
//     COMPANY_NAME,
//     BASE_URL,
//     IMG_URL,
//     API_KEY,
//     BASE,
//     DEFAULT_KEY
// }




