import React,{useState} from 'react';
import {Form,Button} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {AlertMessage,FormInputControl,FormInputGroup} from '../common';
import {toDefaultcurrency} from '../../helper';
import EwalletService from '../../../service/ewallet/Ewallet';
import {isNumber} from '../../validation'
//verify the number only


function ModalForms({balance,transFee,submitSuccess,handleClose}) {
    const service = new EwalletService();
    const {t} = useTranslation();
    const Currency       = useSelector(state => state.curr);
    const [state,setState] = useState({
        toUsername : '',
        Amount : '',
        AvailableAmount : (balance * Currency.value),
        Notes : '',
        Password : '',
        IsError : false,
        notifyMessage   : '',
        notyfiType      : '',
        header          : 'Error!',
        validated : false,
        FormError : {
            toUsername : '',
            Amount : '',
            AvailableAmount : '',
            Notes : '',
            Password : '',
        }
     });
     const {
        //  validated,
         FormError,
         IsError,
         notifyMessage,
         notyfiType,
         header
     } = state

     //binding the text changes
     const textChangeHandler = (e) =>{
        e.preventDefault();
        const  { name , value} = e.target;
        FormError.Password='';
        FormError.toUsername = '';
        FormError.Amount = '';
        FormError.Notes = '';
        setState(prev => ({
            ...prev,
            validated : false,
            [name] : value
          }));
     }

     //notified closed handler
     const notifyDissmissed = () =>{
        setState(prev => ({
            ...prev,
            IsError : false,
            notifyMessage : ''
          }));
          if(notyfiType ==='success'){
              handleClose()
          }
     }


     //on submit handler
    const submitForm = (e) =>{
        e.preventDefault();
        let valid = true;
        valid = validation('username')??false; 
        valid = validation('amount')??false; 
        valid = validation('notes')??false; 
        valid = validation('password')??false; 
        if(valid){
            let NewAmount =parseInt(toDefaultcurrency(state.Amount,Currency.value));
            let formData = new FormData();
            formData.append('to_user_name',state.toUsername);
            formData.append('amount',NewAmount);
            formData.append('transaction_note',state.Notes);
            formData.append('pswd',state.Password);
            formData.append('tran_fee',transFee);
            service.fundTransfer(formData).then((res)=>{
                if(res.status){
                    setState(prev => ({
                        ...prev,
                        FormError,
                        validated : !valid,
                        IsError : true,
                        notifyMessage : t('ewallet.fundTransferSuccessfully'),
                        notyfiType : 'success',
                        header : t('Common.success')
                      }));
                      submitSuccess();
                }else{
                    let error = '';
                    switch (res.error.code) {
                        case 1015:
                            FormError.Password = t('validation.invalid',{field:'transactionPassword'});
                            error= t('validation.invalid',{field:'transactionPassword'})
                            break;
                        case 1004:
                            Object.keys(res.error.fields).map((key) => {
                                if(key.substr(key.length - 4) !== '_err'){
                                    error = res.error.fields[key]
                                    switch(key){
                                        case 'pswd' :
                                            FormError.Password = error;
                                            break;
                                        case 'to_user_name':
                                            FormError.toUsername = error;
                                            break;
                                        case 'amount':
                                            FormError.Amount = error;
                                            break;
                                        case 'transaction_note' : 
                                            FormError.Notes = error;
                                            break;
                                        default:
                                            valid = true
                                            break
                                        }
                                    }
                                    return null
                                }
                                );
                            break;
                        default:
                            break;
                    }
                    setState(prev => ({
                        ...prev,
                        FormError,
                        validated : true,
                        IsError : true,
                        notifyMessage : error,
                        notyfiType : 'danger',
                        header : t('Common.error')
                      }));
                }
            })
        }else{
            setState(prev => ({
                ...prev,
                FormError,
                validated : !valid,
                IsError : true,
                notyfiType : 'danger',
                header : t('Common.error')
              }));
        }
    }

    //form validation
    const validation= (name) =>{
        // const FormError = FormError;
        const values = state;
        let valid= true;
        let error = '';
        switch(name){
            case 'username':
                if(values.toUsername.length===0){
                    FormError.toUsername = t('validation.enterUsername');
                    valid = false;
                    error = t('validation.enterUsername')
                }
                break;
            case 'amount' : 
                valid = false;
                if(values.Amount.length ===0){
                    FormError.Amount = t('validation.enterAmount');
                    error = t('validation.enterAmount')
                }else if(!isNumber(values.Amount)){
                    FormError.Amount = t('validation.onlyDigitsAllowed');
                    error = t('validation.onlyDigitsAllowed');
                }else if(values.Amount <= 0){
                    FormError.Amount = t('validation.valueGreaterThan0');
                    error = t('validation.valueGreaterThan0');
                }else if((parseInt( values.Amount) + parseInt(transFee * Currency.value)) > (balance * Currency.value)){
                    FormError.Amount = t('validation.insufficientBalance');
                    error = t('validation.insufficientBalance');
                }else{
                    FormError.Amount = '';
                    valid = true
                }
                break;
            case 'notes' :
                if(values.Notes.length ===0){
                    FormError.Notes = t('validation.enterNotes');
                    valid = false;
                    error = t('validation.enterNotes');
                } 
                break;
            case 'password':
                if(values.Password.length ===0){
                    FormError.Password = t('validation.transactionPassword');
                    valid= false;
                    error = t('validation.transactionPassword');
                }
                break;
            default:
                valid= true
        }
        if(!valid && error){
            setState(prev => ({
                ...prev,
                notifyMessage : t('validation.checkValueYouHaveSubmitted')
              }));
        }
        setState(prev => ({
            ...prev,
            FormError,
            validated : !valid
          }));
          return valid;
    }
    return (
        <Form onSubmit={submitForm} noValidate >
            <FormInputControl
                label={t("ewallet.transferTo")}
                isRequired={true}
                error={{
                    error : FormError.toUsername
                }}
                inputProps={{
                    name:"toUsername",
                    onChange:textChangeHandler,
                    placeholder : t("ewallet.transferTo")
                }}
            />
            <FormInputGroup
                label={t("Common.amount")}
                groupId="EwalletAmount"
                isPrepend={true}
                isRequired={true}
                error={FormError.Amount}
                inputProps={{
                    name : 'Amount',
                    onChange : textChangeHandler,
                    placeholder : t('Common.amount')
                }}
            />
            <FormInputGroup
                label={t("Common.availableAmount")}
                groupId="EwalletAvailableAmount"
                isPrepend={true}
                isRequired={false}
                // error={FormError.Amount}
                inputProps={{
                    name : 'AvailableAmount',
                    onChange : textChangeHandler,
                    placeholder : t('Common.availableAmount'),
                    disabled : true,
                    value : parseInt(balance).toFixed(Currency.precision)
                }}
            />
            <FormInputControl 
                label={t("Common.notes")}
                isRequired={true}
                error={{
                    error : FormError.Notes
                }}
                inputProps={{
                    name : "Notes",
                    as: "textarea",
                    placeholder : t("Common.notes"),
                    rows:10,
                    onChange:textChangeHandler
                }}
            />
            <FormInputGroup
                label={t("Common.transactionFee")}
                groupId="EwallettransactionFee"
                isPrepend={true}
                isRequired={false}
                inputProps={{
                    name : 'transactionFee',
                    onChange : textChangeHandler,
                    placeholder : t('Common.transactionFee'),
                    disabled : true,
                    value : parseInt(transFee).toFixed(Currency.precision)
                }}
            />
            <FormInputControl 
                label={t("Common.transactionPassword")}
                isRequired={true}
                error={{
                    error : FormError.Password
                }}
                inputProps={{
                    name : "Password",
                    type: "Password",
                    placeholder : t("Common.transactionPassword"),
                    onChange:textChangeHandler
                }}
            />
            <Button variant="info" type="submit">
                {t('Button.submit')}
            </Button>
            { 
                IsError &&
                <AlertMessage 
                    message={notifyMessage}
                    dismiss={notifyDissmissed}
                    type={notyfiType}
                    header={header}
                    show={IsError}
                />
            }
        </Form>
    )
}

export default ModalForms
