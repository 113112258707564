import React, { Component } from 'react';
import {
  BrowserRouter as Router,
  // Route,Switch
} from 'react-router-dom';
import './App.scss';
import './style/customstyle.css';
import RouteMap from './route';
import { createBrowserHistory } from 'history';


function getFaviconEl() {
  return document.getElementById("favicon");
}
export const history = createBrowserHistory({
  basename: process.env.PUBLIC_URL
});

class App extends Component {
  constructor(props) {
    super(props)
    this.state = {
      logo: ''
    }
  }
  componentDidMount() {
    fetch(
      "https://backoffice.a4jfashion.com/backoffice/api/common/app_info", {
        // "http://demo8.infinitemlmdemo.com/julisa/backoffice/api/common/app_info", {      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        // 'api-key': 'ecb58d1f-e6f0-49ad-b63e-0d77a54aa31d'
        'api-key': '6777e535-0c38-44e5-858f-f9f9823e518c' 
      },
    })
      .then((res) => res.json())
      .then((json) => {
        console.log(json.data.company_info.favicon)
        this.setState({
           logo: json.data.company_info.favicon
          // logo: null
        });

        this.handleGoogle();
      })
  }
  handleGoogle() {
    //console.log("The logo in this state is ",this.state.logo);
    const favicon = getFaviconEl(); // Accessing favicon element
    favicon.href ="https://backoffice.a4jfashion.com/uploads/images/logos/" + this.state.logo; };
    // favicon.href ="http://demo8.infinitemlmdemo.com/julisa/uploads/images/logos/" + this.state.logo;  };
  render() {
    return (
      <Router>
        <RouteMap />
      </Router>
    )
  }
}
export default App;
