import {BASE_URL} from '../../configuration/apiconfig';
import UserStore from '../../store/UserStore';

export default class ProfileService{
    key=localStorage.getItem('apiKey')
    async getDetails(){
        return new Promise((resolve, reject) =>{
            fetch(BASE_URL+'profile/view', {
                method: 'GET',
                headers : {
                    'Accept'        : 'application/json',
                    'Content-Type'  : 'application/json',
                    'api-key'       : this.key,
                    'access-token'  : UserStore.key
                },
                })
                .then((response) => {
                    return response.json();
                })
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        })
    }


    //change country name select state
    async countryChange(countryID){
        return new Promise((resolve, reject) =>{
            fetch(BASE_URL+'profile/countryChange?country_id='+countryID, {
                method: 'GET',
                headers : {
                    'Accept'        : 'application/json',
                    'Content-Type'  : 'application/json',
                    'api-key'       : this.key,
                    'access-token'  : UserStore.key
                },
                })
                .then((response) => {
                    return response.json();
                })
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        })
    }

    //update personal details data 
    async UpdatePersonalDetails(data){
        return new Promise((resolve, reject) =>{
            fetch(BASE_URL+'profile/personalDetails', {
                method: 'put',
                headers : {
                    // 'Accept'        : 'application/json',
                    'Content-Type'  : 'application/json',
                    'api-key'       : this.key,
                    'access-token'  : UserStore.key
                },
                body: JSON.stringify(data),
                })
                .then((response) => {
                    return response.json();
                })
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        })
    }

    //update contact details
    async UpdateContactDetails(data){
        return new Promise((resolve, reject) =>{
            fetch(BASE_URL+'profile/contactDetails', {
                method: 'PUT',
                headers : {
                    // 'Accept'        : 'application/json',
                    'Content-Type'  : 'application/json',
                    'api-key'       : this.key,
                    'access-token'  : UserStore.key
                },
                body: JSON.stringify(data),
                })
                .then((response) => {
                    return response.json();
                })
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        })
    }

    //update bank detials
    async UpdateBankDetails(data){
        return new Promise((resolve, reject) =>{
            fetch(BASE_URL+'profile/bankDetails', {
                method: 'PUT',
                headers : {
                    // 'Accept'        : 'application/json',
                    'Content-Type'  : 'application/json',
                    'api-key'       : this.key,
                    'access-token'  : UserStore.key
                },
                body: JSON.stringify(data),
                })
                .then((response) => {
                    return response.json();
                })
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        })
    }

    //update payment details
    async UpdatePaymentDetails(data){
        return new Promise((resolve, reject) =>{
            fetch(BASE_URL+'profile/paymentMethod', {
                method: 'PUT',
                headers : {
                    // 'Accept'        : 'application/json',
                    'Content-Type'  : 'application/json',
                    'api-key'       : this.key,
                    'access-token'  : UserStore.key
                },
                body: JSON.stringify(data),
                })
                .then((response) => {
                    return response.json();
                })
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        })
    }

    //update settings 
    async UpdateSettings(data){
        return new Promise((resolve, reject) =>{
            fetch(BASE_URL+'profile/settingstDetails', {
                method: 'PUT',
                headers : {
                    // 'Accept'        : 'application/json',
                    'Content-Type'  : 'application/json',
                    'api-key'       : this.key,
                    'access-token'  : UserStore.key
                },
                body: JSON.stringify(data),
                })
                .then((response) => {
                    return response.json();
                })
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        })
    }


    //get the KYC Detials
    async getKYCDetails(){
        const response = await fetch(BASE_URL+'profile/kyc_uploads', {     //url
            method: 'GET',                 //method
            headers : {                     //passing header 
                // 'Accept'        : 'application/json',
                // 'Content-Type'  : 'application/json',
                'api-key'       : this.key,
                'access-token'  : UserStore.key
            }
        })
        const res = await response.json();
        return res;
    }

    //get the KYC Detials
    async uploadFile(formData){
        const response = await fetch(BASE_URL+'profile/kyc_file_uploads', {     //url
            method: 'POST',                 //method
            headers : {                     //passing header 
                // 'Accept'        : 'application/json',
                // 'Content-Type'  : 'application/json',
                'api-key'       : this.key,
                'access-token'  : UserStore.key
            },
            body:formData
        })
        const res = await response.json();
        return res;
    }

    //remove the docfiles
    async deletefile(id){
        const response = await fetch(BASE_URL+'profile/remove_kyc', {     //url
            method: 'POST',                 //method
            headers : {                     //passing header 
                // 'Accept'        : 'application/json',
                'Content-Type'  : 'application/json',
                'api-key'       : this.key,
                'access-token'  : UserStore.key
            },
            body:JSON.stringify(id)
        })
        const res = await response.json();
        return res;
    }

    //get the package upgrade details 
    async upgradeDetails(){
        const response = await fetch(BASE_URL+'profile/upgrade_package', {     //url
            method: 'GET',                 //method
            headers : {                     //passing header 
                // 'Accept'        : 'application/json',
                'Content-Type'  : 'application/json',
                'api-key'       : this.key,
                'access-token'  : UserStore.key
            },
        })
        const res = await response.json();
        return res;
    }

    // get subscription details
    async subscriptionDetails() {
        const response = await fetch(BASE_URL+'profile/subscription_details', {     //url
            method: 'GET',                 //method
            headers : {                     //passing header 
                // 'Accept'        : 'application/json',
                'Content-Type'  : 'application/json',
                'api-key'       : this.key,
                'access-token'  : UserStore.key
            },
        })
        const res = await response.json();
        return res;
    }

    // getPaymentData
    async getPaymentData(type = '') {
        const response = await fetch(`${BASE_URL}payment/payment_methods?type=${type}`, {     //url
            method: 'GET',                 //method
            headers : {                     //passing header 
                // 'Accept'        : 'application/json',
                'Content-Type'  : 'application/json',
                'api-key'       : this.key,
                'access-token'  : UserStore.key
            },
        })
        const res = await response.json();
        return res;
    }

    //update profile image 
    async uploadImage(image){
        const response = await fetch(BASE_URL+'profile/image', {     //url
            method: 'POST',                 //method
            headers : {
                'api-key'       : this.key,
                'access-token'  : UserStore.key
            },
            body  : image
        })
        if(response.status === 204){
            return { 
                status : true
            }
        }else{
            const res = await response.json();
            return res;
        }
    }

    async getCaptcha(){
        const response = await fetch(BASE_URL+'profile/forget_transaction_password', {     //url
            method: 'GET',                 //method
            headers : {
                'api-key'       : this.key,
                'access-token'  : UserStore.key
            }
        })
        if(response.status === 200){
            return response.blob()
        }else{
            const res = await response.json();
            return res;
        }
    }

    //delete the profile pic
    async deleteImage(){
        const response = await fetch(BASE_URL+'profile/image_delete', {     //url
            method: 'GET',                 //method
            headers : {
                'api-key'       : this.key,
                'access-token'  : UserStore.key
            },
            
        })
        if(response.status === 204){
            return { 
                status : true
            }
        }else{
            const res = await response;
            return res;
        }
    }




    //submit the subscription detials 
    async subscriptionSubmit(data){
        const response = await fetch(BASE_URL+'profile/subscription', {     //url
            method: 'POST',                 //method
            headers : {
                'api-key'       : this.key,
                'access-token'  : UserStore.key,
                'content-type'  : "application/json"
            },
            body  : JSON.stringify(data)
        })
        if(response.status === 204){
            return { 
                status : true
            }
        }else{
            const res = await response.json();
            return res;
        }
    }

    //submit the package_upgrade
    async packageUpgradeSubmit(data){
        const response = await fetch(BASE_URL+'upgrade/upgrade', {     //url
            method: 'POST',                 //method
            headers : {
                'api-key'       : this.key,
                'access-token'  : UserStore.key,
                'content-type'  : "application/json"
            },
            body  : JSON.stringify(data)
        })
        if(response.status === 204){
            return { 
                status : true
            }
        }else{
            const res = await response.json();
            console.log(res);
            return res;
           
        }
    }
}